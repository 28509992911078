// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-building-internal-resource-js": () => import("./../src/pages/building-internal-resource.js" /* webpackChunkName: "component---src-pages-building-internal-resource-js" */),
  "component---src-pages-digital-strategy-consultancy-js": () => import("./../src/pages/digital-strategy-consultancy.js" /* webpackChunkName: "component---src-pages-digital-strategy-consultancy-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-paid-social-media-strategy-and-implementation-js": () => import("./../src/pages/paid-social-media-strategy-and-implementation.js" /* webpackChunkName: "component---src-pages-paid-social-media-strategy-and-implementation-js" */)
}

